@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900|Metamorphous:400,700,900');
@font-face{
  font-family:"ITC Galliard W02 Roman";
  src:url("/fonts/1f63f174-e2d6-4010-9e77-3b75c5da2d21.woff2") format("woff2"),url("/fonts/38b91aca-e933-41cd-aaf7-bbd488da8571.woff") format("woff");
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
